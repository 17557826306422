//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Footer from '@/components/Controls/Footer';
export default {
  name: 'Error404',
  components: {
    'app-sidebar': function appSidebar() {
      return import(
      /* webpackChunkName: "app-sidebar" */
      '@/components/Controls/Sidebar');
    },
    'app-footer': Footer
  },
  data: function data() {
    return {
      showSidebar: false
    };
  },
  computed: {
    link: function link() {
      return this.$store.getters['lang/linkTo']({
        page: ''
      });
    }
  },
  methods: {
    triggerSidebar: function triggerSidebar() {
      this.showSidebar = !this.showSidebar;
    }
  }
};